import React, { useState, useEffect } from "react";
import axios from "axios";
import { navigate } from "gatsby";
import { Input, Form, Button } from "antd";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import ogImage from "../../images/global_assets/og-image.png";
import HeadingBuilder from "../../components-v2/Base/HeadingBuilder";
import Image from "../../components/ImageQuerys/RandomImages";
import chevron from "../../images/svg/chevron_left.svg";

const { TextArea } = Input;

export default function Demo({ id }) {
  const [errors, setFormErrors] = useState({
    name: "",
    phone: "",
  });
  const [httpError, setHttpError] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    text: "",
  });
  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const submitForm = () => {
    let err = false;
    if (!formData.name) {
      err = true;
      setFormErrors((prevState) => ({
        ...prevState,
        name: "Name is required",
      }));
    } else {
      setFormErrors((prevState) => ({
        ...prevState,
        name: "",
      }));
    }
    if (formData.phone.length < 10) {
      err = true;
      setFormErrors((prevState) => ({
        ...prevState,
        phone: "Phone must be 10 digits",
      }));
    } else {
      setFormErrors((prevState) => ({
        ...prevState,
        phone: "",
      }));
    }

    if (!err && id) {
      const payload = {
        ...formData,
        contact_phone_number: `+1${formData.phone}`,
      };
      delete payload.phone;
      console.log(payload);
      axios({
        method: "post",
        url: `https://growth-service.spoton.com/api/concierge/request_callback/${id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
      })
        .then(() => {
          window.sessionStorage.setItem("Name", formData.name);
          navigate("/thank-you");
        })
        .catch((error) => {
          console.log(errors);
          if (error.message) {
            setHttpError(error.message);
          }
        });
    }
  };

  return (
    <Layout noFooter hideBanner specialHeader className="force-original">
      <SEO
        title="Concierge callback form"
        image={`https://spoton.com/${ogImage}`}
      />
      <section
        className="grid grid-cols-1 lg:grid-cols-2 relative min-h-[100vh] md:min-h-[calc(100vh-72px)] max-h-[1080px] max-w-[100vw]"
        style={{ marginTop: 0, paddingTop: 0 }}
      >
        <div className="relative xl:pb-0 z-10 ">
          <HeadingBuilder
            level={2}
            injectionType={1}
            symbol="."
            withBlueSymbol
            className="max-w-[225px] md:max-w-[448px] ml-4 lg:mx-auto mb-0 relative mt-[48px] md:mt-[0] md:translate-y-[70%] text-left "
          >
            Request a Callback
          </HeadingBuilder>
          <div className="mx-auto lg:translate-y-[90px] xl:translate-y-[-60px] hidden lg:block">
            <Image imageName="callback-main.png" className="mx-auto" />
          </div>
        </div>

        <div className="lg:self-center lg:justify-self-center lg:max-w-[388px] mx-4 md:p-0 relative z-10">
          <Form
            layout="vertical"
            className="xl:translate-y-0"
            onFinish={submitForm}
          >
            <Form.Item
              label="Your Name"
              validateStatus={errors.name && "error"}
              help={errors.name}
            >
              <Input
                placeholder="Your name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                size="large"
                className="mb-5"
              />
            </Form.Item>

            <Form.Item
              label="Phone Number"
              validateStatus={errors.phone && "error"}
              help={errors.phone}
            >
              <Input
                prefix="+1"
                placeholder="(800) 564-0174"
                name="phone"
                pattern="[0-9]*"
                value={formData.phone}
                onChange={(e) =>
                  e.target.validity.valid ? handleChange(e) : formData.phone
                }
                size="large"
                maxLength={10}
              />
            </Form.Item>
            <Form.Item label="Provide any additional details">
              <TextArea
                rows={5}
                placeholder="Text field"
                className="mb-5 rounded-[4px]"
                name="text"
                value={formData.text}
                onChange={handleChange}
              />
            </Form.Item>
            <div className="max-w-[278px] lg:max-w-[316px]">
              <p className="text-black-300 text-[14px]  ">
                Click “Call me now” and receive a call from our support team in
                minutes.
              </p>
            </div>
            <Button
              type="primary"
              htmlType="submit"
              className="cta-primary"
              style={{ marginTop: 20 }}
              size="small"
            >
              Call me now
              <img
                src={chevron}
                alt="chevron icon"
                style={{ margin: "5px 5px 2px 10px" }}
              />
            </Button>
            <span className="text-[#ff4d4f]">{httpError}</span>
          </Form>
        </div>
        <div className="w-[150px] md:w-[200px] absolute right-[15px] lg:right-[53px] bottom-0">
          <Image imageName="callback-rect.png" />
        </div>
        <div className="w-[50px] absolute right-[12px] lg:right-[53px] top-[5%]">
          <Image imageName="callback-half-circle.png" />
        </div>
        <div className="w-[54px] lg:w-[90px] absolute left-[60%] lg:left-[15%] xl:left-[35%] top-[15%] md:top-[15%] lg:top-[30%]">
          <Image imageName="callback-circle.png" />
        </div>
        <div className="w-[90px] absolute left-[60%] lg:left-[2%] top-[-3%] lg:top-[2%]">
          <Image imageName="callback-transparent.png" />
        </div>
        <div className="w-[30px] absolute left-[2%] top-[70%] hidden lg:block">
          <Image imageName="callback-thin-circle.png" />
        </div>
        <div className="w-[60px] absolute left-[40%] top-[70%] hidden xl:block">
          <Image imageName="callback-full.png" />
        </div>
      </section>
    </Layout>
  );
}
